.notification {
  z-index: 102;
  min-height: 50px;
  width: 100%;
  margin-bottom: 0;
  padding: 10px 20px;
  position: fixed;
  left: 0;
  box-shadow: 0 4px rgba(214, 211, 214, .2);
}

.notification.ok {
  background-color: #3f8a93;
}

.notification.error {
  background-color: #eb5757;
}

.notification.voucher-ok {
  background-color: #cbefe9;
}

.notification.voucher-ok .notification-message {
  text-align: center;
}

.notification.voucher-ok .notification-message p {
  color: #212427;
  letter-spacing: 0;
  margin-bottom: 0;
  font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.notification.cs {
  color: #000;
  background-color: #a6d5d5;
}

.notification .notification-close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .notification-close p, .notification-message p {
    color: #fff;
    letter-spacing: 0;
    margin-bottom: 0;
    font-family: Apercu;
    font-size: 14px;
    font-weight: bold;
  }

  .notification-close p > a, .notification-message p > a {
    text-decoration: underline;
    color: var(--link-color) !important;
    cursor: pointer !important;
  }

  .notification-close p > a:hover, .notification-message p > a:hover {
    text-decoration: none;
  }
}

@media only screen and (min-width: 601px) {
  .notification-close p, .notification-message p {
    color: #fff;
    letter-spacing: 0;
    margin-bottom: 0;
    font-family: Apercu;
    font-size: 16px;
    font-weight: bold;
  }

  .notification-close p > a, .notification-message p > a {
    text-decoration: underline;
    color: #fff !important;
    cursor: pointer !important;
  }

  .notification-close p > a:hover, .notification-message p > a:hover {
    text-decoration: none;
  }
}

@media only screen and (min-width: 768px) {
  .notification-close p, .notification-message p {
    color: #fff;
    letter-spacing: 0;
    margin-bottom: 0;
    font-family: Apercu;
    font-size: 17px;
    font-weight: bold;
  }

  .notification-close p > a, .notification-message p > a {
    text-decoration: underline;
    color: #fff !important;
    cursor: pointer !important;
  }

  .notification-close p > a:hover, .notification-message p > a:hover {
    text-decoration: none;
  }
}

@media only screen and (min-width: 992px) {
  .notification-close p, .notification-message p {
    color: #fff;
    letter-spacing: 0;
    margin-bottom: 0;
    font-family: Apercu;
    font-size: 20px;
    font-weight: bold;
  }

  .notification-close p > a, .notification-message p > a {
    text-decoration: underline;
    color: #fff !important;
    cursor: pointer !important;
  }

  .notification-close p > a:hover, .notification-message p > a:hover {
    text-decoration: none;
  }
}

/*# sourceMappingURL=index.css.map */
